<template>
  <div class="main-body">
    <div class="card-item">
      <a-form ref="formRef" :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol" @finish="onSubmit" @finishFailed="onFinishFailed">
        <div class="order-attention">
          <p>友情链接设置</p>
        </div>
        <a-form-item label="链接列表">
          <div class="muti-link-item" v-for="(item, index) in formState.links" style="margin-bottom: 20px" :key="index">
            <a-row>
              <a-col :span="4">
                <CsImage v-model:imgOne="item.imgUrl" />
              </a-col>
              <a-col :span="20">
                <a-input style="margin-bottom: 6px" v-model:value="item.linkTitle" placeholder="请输入标题" />
                <a-input v-model:value="item.linkUrl" placeholder="请输入跳转地址" />
              </a-col>
            </a-row>
            <CloseCircleOutlined @click="removeLinkItem(item)" class="delete-link-item" />
          </div>
          <a-button type="dashed" block @click="addLinkItem">
            <PlusOutlined />
            添加项
          </a-button>
        </a-form-item>

        <a-form-item :wrapper-col="{ span: 8, offset: 2 }">
          <a-button type="primary" html-type="submit">保存</a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, reactive, ref } from "vue";
import { client_save_config, client_get_config } from "@/addons/client/api";
import { message } from "ant-design-vue";
import { useRoute } from "vue-router";
import { CloseCircleOutlined, PlusOutlined } from "@ant-design/icons-vue";
import CsImage from "@/components/UpImage";
export default defineComponent({
  components: { CsImage, CloseCircleOutlined, PlusOutlined },
  setup() {
    //获取当前应用ID
    const route = useRoute();
    const appid = route.query.appid;

    const formRef = ref();
    const formState = reactive({
      appid: appid,
      links: [],
    });

    const addLinkItem = () => {
      formState.links.push({
        imgUrl: "",
        linkTitle: "",
        linkUrl: "",
      });
    };

    const removeLinkItem = (item) => {
      let index = formState.links.indexOf(item);

      if (index !== -1) {
        formState.links.splice(index, 1);
      }
      message.success("删除成功");
    };

    // console.log(appid);
    const onSubmit = () => {
      formRef.value
        .validate()
        .then(() => {
          client_save_config(formState).then((res) => {
            if (res.status == 200) {
              message.success(res.message);
              getInfo();
            }
          });
        })
        .catch((error) => {
          console.log("error", error);
        });
    };
    const onFinishFailed = (errorInfo) => {
      console.log("Failed:", errorInfo);
    };
    const getInfo = () => {
      client_get_config({ appid: appid }).then((res) => {
        if (res.status == 200) {
          formState.links = res.data.setting.links
            ? res.data.setting.links
            : [];
        }
      });
    };
    onMounted(() => {
      getInfo();
    });
    return {
      formRef,
      formState,
      onSubmit,
      onFinishFailed,
      addLinkItem,
      removeLinkItem,
      labelCol: {
        span: 2,
      },
      wrapperCol: {
        span: 8,
      },
    };
  },
});
</script>
<style scoped lang="less">
.muti-link-item {
  position: relative;
  .delete-link-item {
    position: absolute;
    top: -0;
    right: -20px;
  }
}
</style>